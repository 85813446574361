<template>
    <div v-if="showBox" class="loading-main">
        <div class="spinner">
            <div class="rect1 line"></div>
            <div class="rect2 line"></div>
            <div class="rect3 line"></div>
            <div class="rect4 line"></div>
            <div class="rect5 line"></div>
        </div>
        <div class="center-text">数据加载中，请稍等</div>
    </div>
</template>

<script>
    export default {
        name: 'Loading',
        props: {
        },
        data() {
            return {
                showBox:false,
            }},
        created() {
        },
        mounted:function(){
        },
        methods: {
            // 展示
            showLoadingBox(){
                this.showBox = true
            },
            // 隐藏
            hideBox(){
                this.showBox = false
            }
        }
    }
</script>

<style scoped>
    .loading-main{
        position: fixed;
        top: 0;
        left: 0;
        z-index:9999;
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #55aaff;
    }
    .center-text{
        font-size: 18px;
        color: #ffffff;
        margin-top: 15px;
    }
    .spinner {
        width: 100px;
        height: 120px;
        text-align: center;
        font-size: 10px;
    }

    .spinner .line {
        background-color: #ffffff;
        margin-left: 10px;
        height: 100%;
        width: 10px;
        display: inline-block;

        -webkit-animation: stretchdelay 1.2s infinite ease-in-out;
        animation: stretchdelay 1.2s infinite ease-in-out;
    }

    .spinner .rect2 {
        -webkit-animation-delay: -1.1s;
        animation-delay: -1.1s;
    }

    .spinner .rect3 {
        -webkit-animation-delay: -1.0s;
        animation-delay: -1.0s;
    }

    .spinner .rect4 {
        -webkit-animation-delay: -0.9s;
        animation-delay: -0.9s;
    }

    .spinner .rect5 {
        -webkit-animation-delay: -0.8s;
        animation-delay: -0.8s;
    }

    @-webkit-keyframes stretchdelay {
        0%, 40%, 100% { -webkit-transform: scaleY(0.4) }
        20% { -webkit-transform: scaleY(1.0) }
    }

    @keyframes stretchdelay {
        0%, 40%, 100% {
            transform: scaleY(0.4);
            -webkit-transform: scaleY(0.4);
        }  20% {
               transform: scaleY(1.0);
               -webkit-transform: scaleY(1.0);
           }
    }
</style>
