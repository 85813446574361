<template>
    <div>
        <!--点赞-->
        <div v-if="noticeShow" class="success-main">
            <div class="success-box">
                <div class="img-box animated zoomIn">
                    <img class="ok-img" src="https://img.nanshikeji.cn/img/wechat/reg-success.png">
                    <div class="ok-text">做的太棒了！老师给你点赞!</div>
                </div>
            </div>
        </div>
        <!--消息提示-->
        <div></div>
    </div>
</template>

<script>
    export default {
        name: 'NoticeBox',
        props: {},
        data() {
            return {
                noticeShow:false,
                timeNum:5,
                timer:''
            }},
        created() {
        },
        mounted:function(){
        },
        methods: {
            // 展示提示
            showNotice(){
                this.noticeShow = true
                this.startTime()
            },
            // 定时器开启
            startTime(){
                let _this = this
                _this.timeNum = 3
                this.timer = setInterval(function () {
                    if(_this.timeNum > 0){
                        _this.timeNum = _this.timeNum-1
                    }else{
                        clearInterval(_this.timer);
                        _this.noticeShow=false
                    }
                },1000);
            }
        }
    }
</script>

<style scoped>
    /*结束弹窗开始*/
    .success-box{
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0; right: 0; bottom: 0; left: 0;
        background: rgba(0,0,0,0.5);
        text-align: center;
        white-space: nowrap;
        z-index: 9999;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .ok-img{
        width: 15rem;
        height: 15rem;
    }
    .img-box{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .end-button-box{
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 5rem;
    }
    .end-button-box div{
        background-color: #4292f6;
        line-height: 3rem;
        color: #ffffff;
        width: 10rem;
        border-radius: 1rem;
        font-size: 1.2rem;
    }
    .end-info-time{
        color: #ec2908;
        font-size: 1.1rem;
        padding: 0.5rem 0;
    }
    .ok-text{
        font-size: 36px;
        color: #ffffff;
        padding: 20px;
    }
    /*结束弹窗结束*/
</style>
