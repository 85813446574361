<template>
    <div class="video-main">
        <div>
            <video-player class="video-player vjs-custom-skin"
                          ref="videoPlayer"
                          :playsinline="true"
                          style="width:100%; height:100%; display: flex;justify-content: center;align-items: center"
                          :options="playerOptions"
                          @play="onPlayerPlay($event)"
                          @pause="onPlayerPause()"
                          @ended="onPlayerEnded($event)"
                          @waiting="onPlayerWaiting($event)"
                          @playing="onPlayerPlaying($event)"
                          @loadeddata="onPlayerLoadeddata($event)"
                          @canplay="onPlayerCanplay($event)"
                          @canplaythrough="onPlayerCanplaythrough($event)"
                          @ready="playerReadied"
            >
            </video-player>
        </div>
        <div v-if="showLoading" class="loading-box">
            <div>加载中</div>
        </div>
    </div>
</template>

<script>
    import { videoPlayer } from "vue-video-player";
    // require styles npm install vue-video-player@5.0.2 --save
    import 'video.js/dist/video-js.css'
    import 'vue-video-player/src/custom-theme.css'
    export default {
        name: 'VideoPlayer',
        // 添加组件
        components: {
            videoPlayer,
        },
        props: {
            sources: {
                type: String,
                default: ''
            },
        },
        data() {
            return {
                showLoading: false, // 是否显示加载中
                playerOptions: {
                    playbackRates: [0.7, 1.0, 1.5, 2.0], // 可选的播放速度
                    autoplay: true,  // 如果为true,浏览器准备好时开始播放
                    // muted: true,     // 默认情况下将会消除任何音频。
                    loop: false,      // 是否视频一结束就重新开始。
                    // muted: "muted", //谷歌自动播放
                    // techOrder: ["html5"], // 播放类型
                    // html5: { hls: { withCredentials: false } },
                    preload: 'auto',  // none metadata：仅加载视频的元数据（例如时长、尺寸等） auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                    language: 'zh-CN',
                    aspectRatio: '16:9',  // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                    fluid: true,  // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                    sources: [{
                        type: "video/mp4", // 类型   type: "application/x-mpegURL"直播流
                        src: ""
                    }],
                    poster: 'https://img.nanshikeji.cn/img/bg/home-bg.jpg',  // 封面地址
                    notSupportedMessage: '播放失败请联系老师',  // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
                    controls: true,//不显示暂停、声音、进度条组件
                    controlBar: {
                        timeDivider: true,           // 时间分割线
                        durationDisplay: true,       // 显示持续时间
                        progressControl: true, // 进度条
                        remainingTimeDisplay: false, // 是否显示剩余时间功能
                        customControlSpacer: false, // 未知
                        fullscreenToggle: false       // 是否显示全屏按钮
                    }
                }
            }},
        created() {
        },
        mounted:function(){
        },
        computed: {
            player() {
                return this.$refs.videoPlayer.player;
            }
        },
        methods: {
            // 初始化播放器
            initPlayer(){
                /*let _this = this
                this.$nextTick(() => {
                    _this.playerOptions.sources = []
                    let tempUrl = {}
                    tempUrl.type = 'video/mp4'
                    tempUrl.src = _this.sources
                    _this.playerOptions.sources.push(tempUrl)
                    _this.$refs.videoPlayer.player.muted = false
                })*/
                this.playerOptions.sources[0].src = this.sources
                this.player.play();
            },
            // 视频播放完毕 切换到编程界面
            showCoding(){
                this.showCode = true
                this.showVideo = false
            },
            // 开始播放回调
            onPlayerPlay(player) {
                console.log('player 开始!', player)
            },
            // 暂停回调
            onPlayerPause() {
            },
            // 视频播完回调
            onPlayerEnded(player) {
                this.$emit('ended', player)
            },
            // DOM元素上的readyState更改导致播放停止
            onPlayerWaiting() {
            },
            // 已开始播放回调
            onPlayerPlaying() {
            },
            // 当播放器在当前播放位置下载数据时触发
            onPlayerLoadeddata() {
            },
            //媒体的readyState为HAVE_FUTURE_DATA或更高
            onPlayerCanplay() {
            },
            //媒体的readyState为HAVE_ENOUGH_DATA或更高。这意味着可以在不缓冲的情况下播放整个媒体文件。
            onPlayerCanplaythrough() {
            },
            //播放状态改变回调
            playerStateChanged(playerCurrentState) {
                console.log('player current update state', playerCurrentState)
            },
            //将侦听器绑定到组件的就绪状态。与事件监听器的不同之处在于，如果ready事件已经发生，它将立即触发该函数。。
            playerReadied() {
            }
        }
    }
</script>

<style scoped>
    .video-main{
        width: 100%;
        position: relative;
        background-color: #000;
    }
    .video-box{
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

    }
    .show-code{
        z-index: 1000;
    }
    .hide-code{
        z-index: -1;
    }
    .loading-box{
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: #5DA3FA;
        z-index: 99999;
    }
</style>
