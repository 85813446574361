<template>
    <div>
        <div v-if="noticeShow" class="success-main">
            <div class="img-box">
                <div class="message-text">{{text}}</div>
                <div class="close-button">({{timeNum}}秒)后关闭</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'imgBox',
        props: {},
        data() {
            return {
                text:'',
                noticeShow:false,
                timeNum:5,
                timer:''
            }},
        created() {
        },
        mounted:function(){
        },
        methods: {
            // 展示提示
            showNotice(text){
                this.text = text
                this.noticeShow = true
                this.startTime()
            },
            // 定时器开启
            startTime(){
                let _this = this
                _this.timeNum = 5
                this.timer = setInterval(function () {
                    if(_this.timeNum > 0){
                        _this.timeNum = _this.timeNum-1
                    }else{
                        clearInterval(_this.timer);
                        _this.noticeShow=false
                    }
                },1000);
            }
        }
    }
</script>

<style scoped>
    .success-main{
        z-index: 99999;
        position: fixed;
        top: 50px;
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .img-box{
        padding: 20px;
        border: 0.5rem solid #4292f6;
        border-radius: 3rem;
        background-color: rgba(255, 255, 255, 0.9);
        position: relative;
    }
    .close-button{
        background-color: #4292f6;
        line-height: 3rem;
        color: #ffffff;
        width: 150px;
        border-radius: 1rem;
        font-size: 1.2rem;
        text-align: center;
        margin: 10px auto;
    }
    .message-text{
        color: #4292f6;
        font-size: 36px;
        font-weight: bold;
        max-width: 380px;
    }
    .chat-image{
        width: 380px;
        padding-top: 1rem;
    }
</style>
