<template>
    <div class="success-main">
        <div class="success-box" v-if="dialogSuccess">
            <div class="animated zoomIn">
                <img class="ok-img" src="https://img.nanshikeji.cn/img/teaching/success.png">
            </div>
            <div class="end-info-time">{{timeNum}}秒后将进入答题页面</div>
            <div @click="nowToNext()" class="end-button-box">
                <div>立即进入答题页面</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'SuccessBox',
        props: {
            dialogSuccess: {
                type: Boolean,
                default: false
            },
            cid: {
                type: Number,
                default: 0
            },
        },
        data() {
            return {
                timeNum:5,
                timer:''
            }},
        created() {
        },
        mounted:function(){
        },
        methods: {
            // 定时器开启
            startTime(){
                let _this = this
                _this.timeNum = 5
                this.timer = setInterval(function () {
                    if(_this.timeNum > 0){
                        _this.timeNum = _this.timeNum-1
                    }else{
                        clearInterval(_this.timer);
                        _this.toHome()
                    }
                },1000);
            },
            // 立即进入下一节
            nowToNext(){
                clearInterval(this.timer);
                this.toHome()
            },
            // 跳转到首页
            toHome(){
                this.$router.push({path: '/answer?id='+this.cid})
                // this.$router.push({path: '/home'})
            }
        }
    }
</script>

<style scoped>
    /*结束弹窗开始*/
    .success-box{
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0; right: 0; bottom: 0; left: 0;
        background: rgba(0,0,0,0.5);
        text-align: center;
        white-space: nowrap;
        z-index: 9999;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .ok-img{
        width: 15rem;
        height: 15rem;
    }
    .end-button-box{
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 5rem;
    }
    .end-button-box div{
        background-color: #4292f6;
        line-height: 3rem;
        color: #ffffff;
        width: 12rem;
        border-radius: 1rem;
        font-size: 1.2rem;
    }
    .end-info-time{
        color: #ec2908;
        font-size: 1.1rem;
        padding: 0.5rem 0;
    }
    /*结束弹窗结束*/
</style>
