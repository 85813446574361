<template>
    <div class="schedule-main">
        <div @click="closeSchedule()" class="header-box">
            <div class="header-close-text">关闭</div>
            <i class="el-icon-arrow-right"></i>
        </div>
        <div class="step-box">
            <div v-for="(item,index) in scheduleInfo" :key="index">
                <div v-if="index < nowSchedule" class="step-item step-item-ok">
                    <i class="el-icon-success"></i>
                    <span>{{item.name}}</span>
                </div>
                <div v-else-if="index === nowSchedule" class="step-item step-item-now">
                    <i class="el-icon-s-flag"></i>
                    <span>{{item.name}}</span>
                </div>
                <div v-else class="step-item step-item-close">
                    <i class="el-icon-lock"></i>
                    <span>{{item.name}}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Schedule',
        props: {
            scheduleInfo: {
                type: Object,
                default: function () {
                    return {};
                },
            },
            nowSchedule: {
                type: Number,
                default: 0,
            }
        },
        data() {
            return {
                timeNum:5,
                timer:''
            }},
        created() {
        },
        mounted:function(){
        },
        methods: {
            // 关闭弹窗
            closeSchedule(){
                this.$emit('closeSchedule')
            }
        }
    }
</script>

<style scoped>
    .schedule-main{
        background-color: #4a98f8;
        min-height: 100vh;
        width: 100%;
        padding: 15px;
    }
    .header-box{
        color: #ffffff;
        display: flex;
        align-items: center;
        margin: 0 0 30px 0;
        height: 20px;
        line-height: 20px;
    }
    .header-box i{
        padding-top: 2px;
    }
    .header-close-text{
        font-size: 16px;
        padding-right: 10px;
    }
    .step-box{
        display: flex;
        flex-direction: column;
        align-items: center;
        min-height: 80vh;
    }
    .step-item{
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: bold;
        width: 290px;
        height: 30px;
        line-height: 30px;
        padding: 10px;
        border-radius: 10px;
        margin-bottom: 30px;
    }
    .step-item-ok{
        color: #000000;
        background-color: #ffffff;
    }
    .step-item-ok i{
        color: #05a727;
        font-size: 20px;
        padding-top: 2px;
    }
    .step-item-now{
        color: #4a98f8;
        background-color: #ffffff;
    }
    .step-item-now i{
        font-size: 20px;
        padding-top: 2px;
    }
    .step-item-close{
        color: #d5d3d3;
        background-color: #5DA3FA;
    }
    .step-item-close i{
        font-size: 20px;
        padding-top: 2px;
    }
    .step-item span{
        padding-left: 10px;
    }
</style>
