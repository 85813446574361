<template>
    <div v-if="showChat" class="chat-main">
        <!--聊天窗口 - start-->
        <div class="container animated bounceInRight">
            <div class="dialog-chat-box">
                <div class="dialog-chat-title">
                    <div class="dialog-chat-title-left">
                        <span class="tip-green" />
                        <span>聊天记录</span>
                    </div>
                    <div class="hand" @click="hideChat"><i class="el-icon-close" /></div>
                </div>
                <!--中间开始-->
                <div class="dialog-chat-center element">
                    <div id="scroll_text" class="chat-content-box">
                        <div v-for="item in msgList" :key="item.id">
                            <div v-if="item.from_id === scheduleInfo.id" class="dialog-chat-center-item-right">
                                <div class="dialog-chat-center-item-title">{{item.add_time}}</div>
                                <div class="dialog-chat-center-info-right">
                                    <div class="dialog-chat-center-info-text">
                                        <div class="name-box">{{ item.from_name }}</div>
                                        <div v-if="item.message_type === 1" class="dialog-chat-center-info-text-lit-right">
                                            {{ item.content }}
                                        </div>
                                        <div v-else-if="item.message_type === 2" class="chat-center-info-img">
                                            <el-image
                                                    class="chat-image"
                                                    :src="item.content"
                                                    :preview-src-list="[item.content]"
                                                    fit="scale-down" />
                                        </div>
                                        <div @click="playVoice(item)" v-else-if="item.message_type === 3" class="chat-center-info-voice-right">
                                            <div class="box">
                                                <div class="wifi-symbol">
                                                    <div class="wifi-circle first"></div>
                                                    <div class="wifi-circle second"></div>
                                                    <div class="wifi-circle third"></div>
                                                </div>
                                            </div>
                                            <span>点击播放语音</span>
                                        </div>
                                        <div v-else>-</div>
                                    </div>
                                    <div class="dialog-chat-center-info-header">
                                        <el-image class="header-img-box" :src="item.from_avatar?item.from_avatar:'https://img.nanshikeji.cn/img/chat/boy.png'" />
                                    </div>
                                </div>
                            </div>
                            <div v-else class="dialog-chat-center-item">
                                <div class="dialog-chat-center-item-title">{{item.add_time}}</div>
                                <div class="dialog-chat-center-info">
                                    <div class="dialog-chat-center-info-header">
                                        <el-image class="header-img-box" :src="item.from_avatar?item.from_avatar:'https://img.nanshikeji.cn/img/chat/girl.png'" />
                                    </div>
                                    <div class="dialog-chat-center-info-text">
                                        <div class="name-box-right">{{ item.from_name }}</div>
                                        <div v-if="item.message_type === 1" class="dialog-chat-center-info-text-lit">
                                            {{ item.content }}
                                        </div>
                                        <div v-else-if="item.message_type === 2" class="chat-center-info-img">
                                            <el-image
                                                    class="chat-image"
                                                    :src="item.content"
                                                    :preview-src-list="[item.content]"
                                                    fit="scale-down" />
                                        </div>
                                        <div @click="playVoice(item)" v-else-if="item.message_type === 3" class="chat-center-info-voice-left">
                                            <div class="box">
                                                <div class="wifi-symbol">
                                                    <div class="wifi-circle first"></div>
                                                    <div class="wifi-circle second"></div>
                                                    <div class="wifi-circle third"></div>
                                                </div>
                                            </div>
                                            <span>点击播放语音</span>
                                        </div>
                                        <div v-else>-</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="chat-input">
                            <el-input
                                    v-model="textarea"
                                    type="textarea"
                                    :rows="3"
                                    placeholder="请输入内容"
                            />
                        </div>
                        <div class="send-button">
                            <el-upload
                                    :on-change="fileChange"
                                    action="#"
                                    :auto-upload="false"
                                    :show-file-list="false"
                                    multiple
                            >
                                <el-button size="small" class="filter-item" type="primary" icon="el-icon-picture-outline">
                                    图片
                                </el-button>
                            </el-upload>
                            <recording @sendVoice="toSendVoice"></recording>
                            <el-button size="small" class="filter-item" type="primary" icon="el-icon-position" @click="sendText">
                                发送
                            </el-button>
                        </div>
                    </div>
                </div>
                <!--中间结束-->
            </div>
        </div>
        <!--聊天窗口 - end-->
    </div>
</template>

<script>
    import Recording from './Recording.vue';
    import {
        uploadImg
    } from '@/api/app'
    export default {
        name: 'Chat',
        // 添加组件
        components: {
            Recording
        },
        props: {
            scheduleInfo:{
                type: Object,
                default: function () {
                    return {};
                },
            },
            showChat: {
                type: Boolean,
                default: false
            },
            msgList:{
                type: Array,
                default: function () {
                    return [];
                },
            }
        },
        data() {
            return {
                textarea:'',
                userInfo: '',
                audio:new Audio(), // 语音播放插件
                isPlaying: false // 判断上一个是否播放完毕
            }},
        created() {
            let userInfo = sessionStorage.getItem('userInfo')
            if(userInfo) {
                this.userInfo = JSON.parse(userInfo)
            }
        },
        mounted:function(){
        },
        methods: {
            //隐藏聊天窗口
            hideChat(){
                this.$emit('hideChat')
            },
            // 播放语音
            playVoice(item){
                let _this = this
                this.audio.src = item.content
                if (this.isPlaying === true) {
                    this.audio.pause(); //暂停
                    this.audio.currentTime = 0
                    // return
                }
                this.isPlaying = true
                let playStatus = this.audio.play() // 播放
                if (playStatus) {
                    playStatus.then(() => {
                        // 音频加载成功
                        // 音频的播放需要耗时
                        console.log(this.audio.duration)
                        setTimeout(() => { // 后续操作(同为播放完成)
                            _this.isPlaying = false
                        }, this.audio.duration * 1000) // audio.duration 为音频的时长单位为秒
                    }).catch((e) => {
                        // 失败
                        console.log(e)
                    })
                }
            },
            // 发送文本消息
            sendText(){
                if(this.textarea === ''){
                    this.$message({message: '请先输入内容', type: 'error',duration: 3000});
                    return;
                }
                this.sendInfo(1,this.textarea)
            },
            // 发送图片消息
            sendImg(url){
                this.sendInfo(2,url)
            },
            // 发送语音消息
            toSendVoice(info){
                console.log(info)
                this.sendInfo(3,info)
            },
            // 发送消息
            sendInfo(type,info){
                const ws = this.$globalWebSocket.ws
                const _this =this
                let time = new Date()
                let nowTime = _this.timestampToTime(time.toLocaleString('en-US',{hour12: false}).split(" "))
                const token = sessionStorage.getItem('token')
                const sendData = {}
                sendData.token = token
                sendData.type="say";
                sendData.from_id = this.scheduleInfo.id
                sendData.from_name = this.scheduleInfo.user_name
                sendData.from_avatar = this.scheduleInfo.user_avatar
                sendData.message_type = type // 消息类型 1 文字 2 图片 3 语音
                sendData.class_id = this.scheduleInfo.c_u_id?this.scheduleInfo.c_u_id:''
                sendData.to_id = this.scheduleInfo.t_id
                sendData.to_name = this.scheduleInfo.t_name
                sendData.to_avatar = ''
                sendData.content = info
                sendData.add_time = nowTime
                this.msgList.push(sendData)
                const postJson = JSON.stringify(sendData)
                ws.send(postJson)
                this.textarea = ''
                this.$nextTick(() => {
                    const textarea = document.getElementById('scroll_text')
                    textarea.scrollTop = textarea.scrollHeight
                })
            },
            // 图片上传验证
            fileChange(file) {
                const typeArr = ['image/jpeg', 'image/png', 'image/jpg']
                const isJPG = typeArr.indexOf(file.raw.type) !== -1
                const isLt3M = file.size / 1024 / 1024 < 10

                if (!isJPG) {
                    this.$message.error('只能上传jpeg或png文件!')
                    this.$refs.upload.clearFiles()
                    return
                }
                if (!isLt3M) {
                    this.$message.error('上传大小不能超过 10MB!')
                    this.$refs.upload.clearFiles()
                    return
                }
                this.toUpload(file.raw)
            },
            // 上传
            toUpload(files) {
                const _this = this
                if (files === '') {
                    this.$message.error('请先选择图片!')
                    return
                }
                const loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                })
                const fd = new FormData()
                fd.append('file', files)
                uploadImg(fd).then(response => {
                       _this.sendImg(response.data)
                        loading.close()
                    }, error => {
                        console.log(error)
                        loading.close()
                    }
                )
            },
            // 收到新消息
            newMessage(data){
                let _this = this
                // 如果是语音消息 直接播放
                if(data){
                    if(data.message_type === 3){
                        _this.playVoice(data)
                    }
                }
                this.$nextTick(() => {
                    if(_this.showChat === true){
                        const textarea = document.getElementById('scroll_text')
                        textarea.scrollTop = textarea.scrollHeight
                    }
                })
            },
            timestampToTime(times) {
                let time = times[1]
                let mdy = times[0]
                mdy = mdy.split('/')
                let month = parseInt(mdy[0]);
                let day = parseInt(mdy[1]);
                let year = parseInt(mdy[2])
                return year + '-' + month + '-' + day + ' ' + time
            }

        }
    }
</script>

<style scoped>
    /*聊天记录弹窗开始*/
    .chat-main{
        box-shadow: 0px 12px 48px 16px rgba(0, 0, 0, 0.03), 0px 9px 28px 0px rgba(0, 0, 0, 0.05), 0px 6px 16px -8px rgba(0, 0, 0, 0.08);
        border-radius: 5px;
        position: fixed;
        top: 5vh;
        right: 3rem;
        z-index: 1008;
    }
    .chat-main .dialog-chat-box {
        display: inline-block;
        vertical-align: middle;
        text-align: left;
        white-space: normal;
        width: 25rem;
        height: 90vh;
        background: #FFFFFF;
        border-radius: 0.5rem;
        font-size: 1rem;
    }
    .chat-main .dialog-chat-title {
        display: flex;
        justify-content: space-between;
        background: #F1F2F6;
        padding: 1rem;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.85);
    }
    .dialog-chat-title-left{
        display: flex;
        align-items: center;
    }
    .chat-main .tip-green{
        width: 1rem;
        height: 1rem;
        background-color: #07C160;
        border-radius: 50%;
        display: inline-block;
        margin: 0 0.5rem;
    }
    .dialog-chat-center{
        width: calc(100% - 2rem);
        height: calc(100% - 5rem);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 1rem;
    }
    .chat-content-box{
        display : block;
        height: calc(90vh - 5rem - 180px);
        overflow-y: scroll;
    }
    .dialog-chat-center-item{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-bottom: 20px;
        font-size: 12px;
    }
    .dialog-chat-center-item-title{
        width: 100%;
        text-align: center;
        color: #666666;
    }
    .dialog-chat-center-info{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
    }
    .dialog-chat-center-info-header{
        margin-right: 15px;
    }
    .dialog-chat-center-info-text{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 1rem 1rem 0 0;
        color: #666666;
    }
    .dialog-chat-center-info-text-lit{
        padding: 10px;
        width: 200px;
        background: #F3F3F3;
        border-radius: 4px;
        margin-top: 10px;
        color: #000000;
    }
    /*隐藏滚动条开始*/
    .element::-webkit-scrollbar { width: 0 !important }
    .element { -ms-overflow-style: none; }
    .element { overflow: -moz-scrollbars-none; }
    /*隐藏滚动条结束*/
    .dialog-chat-center-item-right{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-bottom: 20px;
        font-size: 12px;
    }
    .dialog-chat-center-info-right{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin: 10px 0 0 0;
    }
    .dialog-chat-center-info-text-lit-right{
        padding: 10px;
        width: 200px;
        background: #d6f1f5;
        border-radius: 4px;
        margin-top: 10px;
        color: #000000;
    }
    .chat-input{
        margin-top: 15px;
    }
    .send-button{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 15px;
    }
    .hand{
        font-size: 1.2rem;
        cursor: pointer;
    }
    .header-img-box{
        width: 3rem;
        height: 3rem;
        border-radius: 100%;
    }
    .name-box{
        text-align: right;
    }
    .chat-image{
        width: 200px;
        padding-top: 1rem;
    }
    .chat-center-info-voice-left{
        padding: 5px 0 5px 20px;
        width: 200px;
        background: #F3F3F3;
        border-radius: 4px;
        margin-top: 10px;
        color: #000000;
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    .chat-center-info-voice-right{
        padding: 5px 0 5px 20px;
        width: 200px;
        background: #d6f1f5;
        border-radius: 4px;
        margin-top: 10px;
        color: #000000;
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    .box {
        box-sizing: border-box;
        position: relative;
    }
    .wifi-symbol {
        width: 25px;
        height: 25px;
        box-sizing: border-box;
        overflow: hidden;
        transform: rotate(135deg);

    }
    .wifi-circle {
        border: 2px solid #999999;
        border-radius: 50%;
        position: absolute;
    }

    .first {
        width: 5px;
        height: 5px;
        background: #cccccc;
        top: 22px;
        left: 22px;
    }
    .second {
        width: 12px;
        height: 12px;
        top: 17px;
        left: 17px;
        animation: fadeInOut 1s infinite 0.2s;
    }
    .third {
        width: 20px;
        height: 20px;
        top: 12px;
        left: 12px;
        animation: fadeInOut 1s infinite 0.4s;
    }
    @keyframes fadeInOut {
        0% {
            opacity: 0; /*初始状态 透明度为0*/
        }
        100% {
            opacity: 1; /*结尾状态 透明度为1*/
        }
    }
    /*聊天记录弹窗结束*/
</style>
