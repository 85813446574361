<template>
    <div class="main">
        <!--头部 - start-->
        <div v-if="showHeader === true" class="header-box">
            <div @click="backTo()">
                <div class="back-icon">
                    <i class="el-icon-arrow-left" style="font-weight: bold" ></i>
                    <span>返回</span>
                </div>
            </div>
            <div class="header-button-box">
                <div @click="submit()" class="header-save-button green">
                    <i class="el-icon-folder-checked"></i>
                    <span>提交</span>
                </div>
                <div @click="toRedo()" class="header-save-button red">
                    <i class="el-icon-refresh-left"></i>
                    <span>重做</span>
                </div>
                <div @click="openMenu()" class="header-save-button blue">
                    <i class="el-icon-notebook-2"></i>
                    <span>菜单</span>
                </div>
            </div>
            <!--聊天按钮 - start-->
            <div class="chat-button-box" @click="showChatBox()" >
                <el-badge :value="newMessageCount" :hidden="newMessageCount>0?false:true" :max="99" class="item">
                    <div class="chat-button">
                        <i class="el-icon-s-comment"></i>
                        <span>联系老师</span>
                    </div>
                </el-badge>
            </div>
            <!--聊天按钮 - end-->
        </div>
        <!--头部 - end-->
        <!--视频播放 -->
        <div :class="showVideo?'show-code animated fadeIn':'hide-code'" class="video-box animated zoomIn">
            <video-player ref="player" :sources="sources" @ended="changeSchedule()"></video-player>
        </div>
        <!--进度查看-->
        <el-drawer :size="390" :with-header="false" :visible.sync="drawer" direction="rtl">
            <schedule :nowSchedule="schedule" :scheduleInfo="list" ref="schedule" @closeSchedule="closeMenu()"></schedule>
        </el-drawer>
        <!--顶部聊天消息提示-->
        <top-message ref="topMessage"></top-message>
        <!--单节完成弹窗-->
        <message-box ref="endBox" @timeEnd="toNext()" :dialogEnd="dialogEnd"></message-box>
        <!--图片展示-->
        <show-img ref="showImg"></show-img>
        <!--加载-->
        <loading ref="loadingBox"></loading>
        <!--事件提示-->
        <notice ref="noticeBox"></notice>
        <!--学习完成弹窗-->
        <success ref="successBox" :dialogSuccess="dialogSuccess" :cid="cid"></success>
        <!--聊天弹窗 -->
        <chat :showChat="showChat" :scheduleInfo="scheduleInfo" :msgList="msgList" ref="chat" @hideChat="closeChat()"></chat>
        <!--编程界面 - start-->
        <div :class="showCode?'show-code animated fadeIn':'hide-code animated zoomOut'" class="code-main-box">
            <iframe :key="key" ref="iframe" src="/show/index.html" style="width: 100%;height: 100%;" frameborder="no" border="0" marginwidth="0" marginheight="0"></iframe>
        </div>
        <!--编程界面 - end-->
    </div>
</template>

<script>
    import {
        getClassInfo,
        getSchedule,
        updateSchedule,
        getChatList,
        getScheduleUrl,
        updateEvent
    } from '@/api/app'
    import VideoPlayer from "./components/VideoPlayer.vue";
    import Chat from "./components/Chat.vue";
    import MessageBox from "./components/MessageBox.vue";
    import Success from "./components/Success.vue";
    import Schedule from "./components/Schedule.vue";
    import Loading from "./components/Loading.vue";
    import Notice from "./components/Notice.vue";
    import ShowImg from "./components/ShowImg.vue";
    import TopMessage from "./components/TopMessage.vue";
    export default {
        name: 'Teaching',
        // 添加组件
        components: {
            VideoPlayer,
            Chat,
            MessageBox,
            Success,
            Schedule,
            Loading,
            Notice,
            ShowImg,
            TopMessage
        },
        data() {
            return {
                ws: '', // websocket连接
                showCode: false, // 是否展示编程界面
                showVideo: false, // 是否展示
                key:1,
                sources: '', // 视频资源链接
                id: '', // 用户课程id
                cid: '', // 课程id
                list: '', // 流程列表
                schedule: 0, // 进度
                scheduleStartTime: 0, // 进度开始时间
                scheduleEndTime: 0, // 进度结束时间
                scheduleCount: 0, // 进度操作次数
                testCount:0, // 进度调试次数
                scheduleInfo: {}, // 进度信息
                dialogEnd: false, // 当前进度完成弹窗
                showChat: false, // 是否展示聊天窗口
                msgList: [], // 聊天记录
                newMessageCount: 0,
                showHeader:false,
                dialogSuccess: false,// 学习完成弹窗
                needLoad: false, // 判断是否需要加载课件
                drawer: false, // 任务列表
            }},
        created() {
            const query = this.$route.query
            this.id = query.id
            this.cid = parseInt(query.cid)
            this.getClassSchedule()
            // 连接websocket
            this.connectWebSocket()
        },
        mounted:function(){
            this.iframeWin = this.$refs.iframe.contentWindow;
            window.addEventListener("message", this.handleMessage);
        },
        methods: {
            // 打开菜单
            openMenu(){
                this.drawer = true
            },
            // 关闭菜单
            closeMenu(){
                this.drawer = false
            },
            // 获取课程信息
            getInfo(){
                let postData = {}
                postData.c_id = this.cid
                getClassInfo(postData).then(res => {
                    // 重置学习进度时间
                    this.scheduleStartTime = this.getNowTime()
                    this.scheduleCount = 0
                    this.testCount = 0
                    this.list = res.data
                    // 如果当前不是播放视频 往前退一步 播放视频
                    if(this.list[this.schedule].type !== 1) {
                        this.schedule = this.schedule-1
                        this.needLoad = true
                    }
                    // 初始化播放器
                    this.initPlayer()
                })
            },
            //获取当前时间
            getNowTime() {
                let date = new Date();
                let year = date.getFullYear(); //getFullYear()代替getYear()
                let month = date.getMonth() + 1;
                let day = date.getDate();
                let hour = date.getHours();
                let minute = date.getMinutes();
                let second = date.getSeconds();
                let time = year + '-' + this.addZero(month) + '-' + this.addZero(day) + ' ' + this.addZero(hour) + ':' + this.addZero(minute) + ':' + this.addZero(second);
                return time;
            },
           //小于10的拼接上0字符串
            addZero(s) {
                return s < 10 ? ('0' + s) : s;
            },
            // 点击重做
            toRedo(){
                this.$confirm('此操作将恢复到最近一次提交, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.loadCodingOld()
                }).catch(() => {
                    this.$message({type: 'info', message: '已取消重做'});
                });
            },
            // 加载历史课件
            loadCodingOld(){
                let _this = this
                this.showCode = true
                this.showHeader = true
                this.showVideo = false
                let postData = {}
                getScheduleUrl(postData).then(res => {
                    if(res.data){
                        let url = res.data.url
                        setTimeout(function(){
                            _this.sendIframeMessage('changeNext',url)
                        },500);
                    }
                })
            },
            // 获取课程进度信息
            getClassSchedule(){
                let postData = {}
                postData.c_id = this.cid
                getSchedule(postData).then(res => {
                    this.scheduleInfo = res.data
                    // 判断是否因为数据异常 进度超过最长进度
                    if(this.scheduleInfo.progress >= this.scheduleInfo.progress_all){
                        this.scheduleInfo.progress = 0
                    }
                    // 判断进度是否已经结束，如果结束就从头开始
                    if(res.data.progress === res.data.progress_all && res.data.study_status === 2) {
                        this.schedule = 0
                    }else{
                        this.schedule = res.data.progress
                    }
                    this.getInfo()
                })
            },
            // 监听倒计时结束
            toNext(){
                this.dialogEnd = false
                this.changeSchedule()
            },
            // 变更进度
            changeSchedule(){
                // 展示加载
                this.showLoadingAnim()
                let _this = this
                this.schedule = this.schedule+1
                // 判断课程是否已结束
                if(this.schedule >= this.scheduleInfo.progress_all){
                    this.dialogSuccess = true
                    setTimeout(function(){
                        _this.$refs.successBox.startTime()
                    },500);
                    this.submitSchedule()
                    return
                }
                if(this.list[this.schedule].type === 1) {
                    this.sendIframeMessage('end')
                    this.initPlayer()
                }else if(this.list[this.schedule].type === 2) {
                    this.showCoding()
                }else if(this.list[this.schedule].type === 3) {
                    this.showCodingOld()
                }
                this.submitSchedule()
            },
            // 展示加载动画
            showLoadingAnim(){
                let _this = this
                this.$refs.loadingBox.showLoadingBox()
                setTimeout(function(){
                    _this.$refs.loadingBox.hideBox()
                },1000);
            },
            // 提交进度到服务器
            async submitSchedule(){
                // 如果已完成状态 进度不更新
                if(this.scheduleInfo.study_status === 2){
                    return ;
                }
                let postData = {}
                postData.allSchedule = this.scheduleInfo.progress_all
                postData.schedule = this.schedule
                postData.type =this.list[this.schedule-1].type === 1?1:2
                postData.scheduleStartTime = this.scheduleStartTime
                postData.scheduleEndTime = this.getNowTime()
                postData.scheduleCount = this.scheduleCount
                postData.testCount = this.testCount
                updateSchedule(postData).then(res => {
                    // 重置学习进度时间
                    this.scheduleStartTime = this.getNowTime()
                    this.scheduleCount = 0
                    this.testCount = 0
                   console.log(res)
                })
            },
            // 切换到编程界面（新课件）
            showCoding(){
                console.log('showCoding')
                this.showCode = true
                this.showHeader = true
                this.showVideo = false
                // 如果没有课件 获取历史课件
                this.sendIframeMessage('changeNext',this.list[this.schedule].url)
            },
            // 切换到编程界面（继续上一个编程程序）
            showCodingOld(){
                console.log('showCodingOld')
                this.showCode = true
                this.showHeader = true
                this.showVideo = false
                // 判断是否需要加载历史课件
                if(this.needLoad === true){
                    this.needLoad = false
                    this.loadCodingOld()
                }
            },
            // 初始化播放器
            initPlayer(){
                let _this = this
                _this.sources = this.list[this.schedule].url
                _this.showVideo = true
                this.showCode = false
                this.showHeader = false
                this.$nextTick(() => {
                    this.$refs.player.initPlayer()
                })
            },
            // 提交数据
            submit(){
                let _this = this
                this.dialogEnd = true
                setTimeout(function(){
                    _this.$refs.endBox.startTime()
                },500);
                // this.changeSchedule()
                this.sendIframeMessage('submit')
            },
            // 接收 iframe
            handleMessage(event) {
                // 获取从iframe页面中传过来的值
                switch (event.data.type) {
                    case 'openFull':
                        this.onOpenFull();
                        break;
                    case 'closeFull':
                        this.onCloseFull();
                        break
                    case 'clickStart':
                        this.onClickStart();
                        break
                    case 'changeBlock':
                        this.onChangeBlock();
                        break
                }
            },
            // 发送消息给 iframe
            sendIframeMessage(type,data) {
                // 在iframe页面中接收通过key也就是param接收，因此传输的数据可以是对象，包含多个key以及对应的数据
                this.iframeWin.postMessage({
                    type: type,
                    data: data
                }, "*");
            },
            // 监听打开全屏
            onOpenFull(){
                this.showHeader = false
            },
            // 监听关闭全屏
            onCloseFull(){
                this.showHeader = true
            },
            // 监听积木移动
            onChangeBlock(){
                this.scheduleCount = this.scheduleCount+1
                this.sendUserEvent(1)
            },
            // 监听点击开始
            onClickStart(){
                this.testCount = this.testCount+1
                this.sendUserEvent(2)
            },
            // 展示弹窗
            showChatBox(){
                let _this = this
                this.showChat = !this.showChat
                this.newMessageCount = 0
                _this.getHistory()
            },
            // 查询聊天信息
            getHistory() {
                let _this = this
                if(this.msgList.length > 0){
                    setTimeout(function(){
                        _this.$refs.chat.newMessage()
                    },500);
                    return false;
                }
                const postData = {}
                getChatList(postData).then(response => {
                    this.msgList = response.data
                    setTimeout(function(){
                        _this.$refs.chat.newMessage()
                    },500);
                })
            },
            // 关闭聊天窗口
            closeChat(){
                this.showChat = false
            },
            // 连接websocket
            connectWebSocket() {
                this.ws = this.$globalWebSocket.ws
                const that =this
                this.ws.onopen = function() { // 绑定连接事件
                }
                this.ws.onmessage = function(evt) { // 绑定收到消息事件
                    that.showMsg(JSON.parse(evt.data))
                }
                this.ws.onclose = function() { // 绑定关闭或断开连接事件
                    console.log('Connection closed.')
                }
                setTimeout(function(){
                    that.toLogin()
                },500);
            },
            // 登录
            toLogin(){
                // 登录
                const token = sessionStorage.getItem('token')
                let login_data={};
                login_data.type="userLogin";
                login_data.token=token;
                login_data=JSON.stringify(login_data);
                this.ws.send(login_data);
            },
            // 发送操作处理事件
            sendUserEvent(type){
                let postData = {}
                postData.eventType = type
                postData.allSchedule = this.scheduleInfo.progress_all
                postData.schedule = this.schedule
                postData.type =this.list[this.schedule-1].type === 1?1:2
                postData.scheduleStartTime = this.scheduleStartTime
                postData.scheduleCount = this.scheduleCount
                postData.testCount = this.testCount
                updateEvent(postData).then(res => {
                    console.log(res)
                })
            },
            // 处理接收到的数据
            showMsg(data){
                switch (data.type) {
                    case 'ping':
                        this.ws.send('{"type":"pong"}');
                        break;
                    case 'say': // 更新发言
                        this.newMsg(data)
                        break
                    case 'saveWorks': // 保存数据
                        this.sendIframeMessage('submitTemp')
                        break
                    case 'sendEvent': // 处理事件
                        this.goEvent(data)
                        break
                }
            },
            // 处理事件
            goEvent(data){
                if(data.info === 'sendEncourage'){
                    this.$message({type: 'success', message: '加油，离成功只有一步之遥了！'});
                }
                if(data.info === 'sendGoods'){
                    this.$refs.noticeBox.showNotice(data)
                }
            },
            // 更新发言信息
            newMsg(data){
                this.msgList.push(data)
                if(this.showChat === true) {
                    this.$refs.chat.newMessage(data)
                }else{
                    if(data.message_type === 1){
                        // 展示弹窗
                        this.$refs.topMessage.showNotice(data.content)
                        // this.$message({showClose: true,type: 'success', message: data.content,duration:6000});
                    }
                    if(data.message_type === 2){
                        this.$refs.showImg.showNotice(data.content)
                    }
                    if(data.message_type === 3){
                        // 展示弹窗
                        this.$message({showClose: true,type: 'success', message: '老师发送了一条语音，请点击聊天窗口查看。',duration:6000});
                    }
                    this.$refs.chat.newMessage(data)
                    this.newMessageCount++
                }
            },
            // 返回上一页
            backTo(){
                window.history.go(-1);
            },
        }
    }
</script>

<style scoped>
    .main{
        width: 100%;
        min-height: 100vh;
        position: relative;
        background-color: #000;
    }
    .video-box{
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;

    }
    .code-main-box{
        display: flex;
        justify-content: center;
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999;
    }
    .show-code{
        z-index: 1000;
    }
    .hide-code{
        z-index: -1;
    }

    .header-box{
        position: fixed;
        top: 0;
        right: 0;
        width: 100vw;
        height: 3rem;
        background-color: #034466;
        z-index: 1001;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .header-logo{
        width: 6rem;
        height: 2.5rem;
        padding-left: 2rem;
    }
    .header-button-box{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-size: 0.9rem;
        color: #ffffff;
        font-weight: bold;
        position: absolute;
        top: 3.3rem;
        left: calc(50vw - 15rem);
        z-index: 1001;
    }
    .header-save-button{
        width: 6rem;
        height: 2.1rem;
        line-height: 2.1rem;
        text-align: center;
        margin-right: 2rem;
        border-radius: 0.5rem;
        display: flex;
        justify-content:center;
        align-items: center;
        cursor: pointer;
        position: relative;
        z-index: 1001;
    }
    .header-save-button span{
        padding-left: 0.5rem
    }
    .header-save-button i{
        font-size: 1.2rem;
    }
    .blue{
        background-color: #4292f6;
    }
    .red{
        background-color: #d20617;
    }
    .green{
        background-color: #048d31;
    }
    .chat-main-box{
        position: fixed;
        top: 5vh;
        right: 3rem;
        z-index: 1008;
    }
    .chat-button-box{
        cursor: pointer;
        margin-right: 4.5rem;
    }
    .chat-button{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 5.5rem;
        height: 2rem;
        line-height: 2.5rem;
        text-align: center;
        font-size: 1rem;
        color: #ffffff;
        background-color: #5DA3FA;
        border-radius: 1rem;
        padding: 0 0.8rem;
    }
    .back-icon{
        padding-left: 2rem;
        font-size: 1.2rem;
        font-weight: bold;
        color: #ffffff;
        cursor: pointer;
    }
</style>
